import { languages } from '~/labels';

type Lang = Array<keyof typeof languages>;

export default function () {
    const preferredLanguage = useState<Lang>('preferred-language', (): Lang => {
        // try to extract the preferred language from the browser
        const browserLanguage = navigator.language.split('-')[0] as Lang[number];
        if (browserLanguage in languages) {
            return [browserLanguage];
        }

        return ['en'];
    });

    return preferredLanguage;
}
